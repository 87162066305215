<template>
  <button ref="dropdown" class="dropdown submit" @click="show()">
    <div>State: {{ project.state }}</div>
    <div ref="content" class="dropdown-content">
      <button @click="updateState(state)" v-for="state in states" :key="state">
        {{ state }}
      </button>
    </div>
  </button>
</template>

<script>
import constants from "@/constants.js";
import { mapActions } from "vuex";

export default {
  props: ["project"],
  data() {
    return {
      states: constants.projectState,
    };
  },
  methods: {
    ...mapActions(["editProject"]),
    show() {
      this.$refs.content.classList.toggle("show")
      this.$refs.dropdown.classList.toggle("show")
    },
    updateState(state) {
      if (this.project) {
        this.editProject({
          state: state,
          _id: this.project._id,
        })
          .then(() => {
            this.$emit("refresh");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-content {
  left: unset;
  right: 0;
}
</style>