<template>
  <div class="panel-content-left">
    <template v-if="project">
      <div class="h-stack h-space-between">
        <div class="heading-title-1">
          {{ project.client.name }} / {{ project.title }} /
          {{ project.subtitle }}
        </div>
        <ProjectStateButton
          v-on:refresh="refresh()"
          :project="project"
        ></ProjectStateButton>
      </div>
      <div class="h-stack h-start pb-3 gap-3">
        <router-link :to="'/projects/detail/' + id + '/overview'"
          >Overview</router-link
        >
        <router-link
          v-if="$store.state.accessLevel > 4"
          :to="'/projects/detail/' + id + '/finance'"
          >Finance</router-link
        >
        <router-link :to="'/projects/detail/' + id + '/crew'">Crew</router-link>
        <router-link :to="'/projects/detail/' + id + '/tasks'"
          >Tasks</router-link
        >
        <router-link :to="'/projects/detail/' + id + '/logs'">Logs</router-link>
      </div>
      <router-view :project="project" v-on:refresh="refresh()"></router-view>
      <a class="heading-title-1">Comments</a>
      <div class="table">
        <div class="table-header comment-row">
          <div class="text-left">Date</div>
          <div class="text-left">Time</div>
          <div class="text-left">User</div>
          <div class="text-left">Message</div>
        </div>
        <div
          v-for="comment in project.comments"
          :key="comment._id"
          class="table-row comment-row"
        >
          <div class="text-left">
            {{ formatDate(comment.timestamp, "DD.MM.YYYY") }}
          </div>
          <div class="text-left">
            {{ formatDate(comment.timestamp, "HH:mm") }}
          </div>
          <div class="text-left">
            {{ comment.user.firstName + " " + comment.user.lastName }}
          </div>
          <div class="text-left">{{ comment.message }}</div>
        </div>
        <div v-if="project.comments.length == 0" class="table-row">
          <div>No comments</div>
        </div>
      </div>
      <div class="v-stack h-stretch pa-3">
        <textarea class="comment" v-model="message"></textarea>
      </div>
      <div class="h-stack h-end">
        <button class="submit" @click="sendComment()">Send</button>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import ProjectStateButton from "@/components/ProjectStateButton.vue";

export default {
  data() {
    return {
      project: null,
      message: "",
    };
  },
  props: ["id"],
  components: {
    ProjectStateButton,
  },
  methods: {
    ...mapActions(["getProject", "addComment"]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    sendComment() {
      if (this.message.trim().length > 0) {
        this.addComment({
          project: this.project._id,
          user: this.$store.state.id,
          timestamp: moment().toISOString(),
          message: this.message,
        })
          .then(() => {
            this.message = "";
            this.refresh();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    refresh() {
      this.getProject(this.id)
        .then((project) => {
          this.project = project;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
  beforeRouteEnter(to, from, next) {
    from;

    next((vm) => {
      if (to.name) {
        vm.$router.push("/projects/detail/" + vm.id + "/overview");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.comment-row {
  grid-auto-columns: 100px 60px 60px 1fr !important;
  gap: 10px;
}

.heading-title-1 {
  padding: unset;
  text-align: left;
  padding-bottom: 12px;
}
</style>